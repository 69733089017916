<template>
  <div>
    <!-- 头部 -->
    <section class="section panel servicehome_header">
      <div class="col-lg-12 container_max">
        <header class="header_title">
          <div class="T48px_Regular colorfff wow animate__fadeInUp">
            <span class="color1D73FF">{{ $t("blolist.header_title1") }}</span>
            <span>{{ $t("blolist.header_title2") }}</span>
          </div>

          <span
            data-wow-delay=".5s"
            class="T16px_Regular wow animate__fadeInUp"
          >
            {{ $t("blolist.header_tips") }}
          </span>
        </header>

        <div class="col-lg-12 flex-w">
          <div
            class="col-lg-4 info_item_box_col wow animate__fadeInUp"
            @click="routerBtn(item.routerUrl)"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="flex-w info_item_box">
              <div class="blog_image_logo_box">
                <img class="blog_image_logo" :src="item.img" alt="" />
              </div>

              <div class="info_box flex-c">
                <span class="T12px_Regular time">{{ item.time }}</span>

                <span class="T24px_Regular_ya title colorfff">
                  {{ item.title }}
                </span>

                <footer class="T14px_Regular footer_info">
                  <span class="colorfff">{{ $t("home.gengduo") }}</span>
                  <img
                    class="jq_icon"
                    src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/jq.png"
                    alt=""
                  />
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 项目总结 -->
    <section class="section panel project_summarize_box">
      <div class="customized_box flex-wb">
        <div class="flex-c">
          <span class="T36px_Regular colorfff">
            {{ $t("customized.title") }}
            <span class="color1D73FF">{{ $t("customized.title1") }}</span>
          </span>
          <span class="T16px_Regular color818181">
            {{ $t("customized.tipe") }}
          </span>
        </div>
        <div class="flex-c">
          <span class="T14px_Regular colorfff tips_net">
            {{ $t("customized.tipe_ri") }}
          </span>
          <span
            @click="routerBtn('/contact')"
            class="T20px_Regular colorfff my_btn animation_right"
          >
            {{ $t("customized.btn") }}
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  data() {
    return {
      list: [
        {
          img: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol1_img.png",
          time: this.$t("home.blog_list1_time"),
          title: this.$t("home.blog_list1_title"),
          routerUrl: "/blobrand",
        },
        {
          img: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol2_img.png",
          time: this.$t("home.blog_list2_time"),
          title: this.$t("home.blog_list2_title"),
          routerUrl: "/blotob",
        },
        {
          img: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol3_img.png",
          time: this.$t("home.blog_list3_time"),
          title: this.$t("home.blog_list3_title"),
          routerUrl: "/bloaddtob",
        },
      ],
    };
  },
  mounted() {
    const wow = new WOW();
    wow.init();
  },
  methods: {
    /**
     * 路由跳转方法
     * @param {*} id 文章id
     * @param {*} url 跳转路由
     */
    routerBtn(url, id) {
      if (id) {
        this.$router.push({ path: url, query: { id: id } });
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.servicehome_header {
  min-height: 100vh;
  background: #15171d;
  padding-top: 5.6875rem;
  padding-bottom: 0;

  .container_max {
    width: 100%;
    padding: 0 5.875rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header_title {
      width: 24.0625rem;
      margin-bottom: 2.625rem;
    }

    .info_item_box_col {
      width: calc(33.33333333% - 1.25rem);
      // width: 100%;
      margin-right: 1.875rem;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    .info_item_box {
      width: 100%;
      margin-bottom: 3.4688rem;
      border: 0.0313rem solid #c3c3c4;

      &:hover {
        .blog_image_logo {
          transform: scale(1.05);
          transition: all 0.8s;
        }
      }

      .blog_image_logo_box {
        width: 100%;
        height: 5.4375rem;
        margin-bottom: 1.5625rem;
        overflow: hidden;

        .blog_image_logo {
          width: 100%;
          height: 100%;
          transition: all 0.8s;
        }
      }

      .info_box {
        padding-left: 1.0938rem;
        padding-bottom: 1.7813rem;
        width: calc(100%);

        .title {
          margin-top: 0.25rem;
          margin-bottom: 3.125rem;
          // height: 1.5625rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }

      .footer_info {
        position: relative;
        // letter-spacing: .125rem;

        .jq_icon {
          width: 0.875rem;
          height: 0.875rem;
          margin-left: 0.25rem;
        }
      }
    }
  }
}

.project_summarize_box {
  background: #15171d;
  padding: 0;
  padding-bottom: 4.6875rem;
  // padding-top: 2.75rem;

  .customized_box {
    background: #15171d;
    margin: 0 5.875rem;
    // margin-top: 3.375rem;
    height: 7.9375rem;
    padding: 0 1.7813rem 0 1.7188rem;
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/dingzhi.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .tips_net {
      height: 0.8438rem;
    }

    .my_btn {
      width: 5.8125rem;
      height: 1.7813rem;
      border: 0.0313rem solid #ffffff;
      text-align: center;
      line-height: 1.7813rem;
    }
  }
}
</style>
